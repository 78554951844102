// https://github.com/azouaoui-med/react-pro-sidebar
// Your variable overrides
$sidebar-bg-color: inherit;
$submenu-bg-color: inherit !default;
$icon-bg-color: inherit;
$submenu-bg-color-collapsed: inherit !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

// Active menu item color
.pro-menu-item.active {
    color: aqua !important;
}

// Hover menu item color
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: aqua;
}

// Disable menu item properties
.slider-menu-item-disabled {
    
    color: gray !important;

    :hover {
        color: gray !important;
        cursor: default;
    }

    :focus {
        color: gray !important;
        cursor: default;
    }
}