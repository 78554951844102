// .bpmn-icon-start-event-none {
//     color: greenyellow !important;
// }

// .bpmn-icon-intermediate-event-none {
//     color: #61ffdd !important;
// }

// .bpmn-icon-end-event-none {
//     color: red !important;
// }

// .bpmn-icon-gateway-none {
//     color: #000 !important;
// }

// .bpmn-icon-task {
//     color: orange !important;
//     stroke: greenyellow;
// }

// Custom palette styles
.djs-palette.open {
    
    border-radius: 7px;
    transition: ease .3s;
    
    .djs-palette-entries {
        border-radius: 7px;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}


/* Elements task BPMN by status */

.task-completed:not(.djs-connection) .djs-visual > :nth-child(1) {
    fill: greenyellow !important;
}