#bootstrap-formio {    
    
    @import '~bootstrap/scss/bootstrap.scss';

    .card {
        padding: 0px !important;
        margin: 5px !important;
    }

    .formarea {
        background-color: #F2F4F6 !important;
        color: #000 !important;
    }

    .formcomponents {
        background-color: #F2F4F6 !important;
    }

    /* FormIO main container */
    .formbuilder, .formio-component-form { 
        padding: 16px !important;
        margin: 5px !important;
        background-color: #F2F4F6 !important;
        border-radius: 7px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: #000;
    }

    .card-header {
        padding: 5px !important;
    }

    /* Edit component buttons options */
    .btn.btn-primary.btn-sm.btn-block.formcomponent.drag-copy {
        display: block !important;
    }
}

.formio-dialog {
    @import '~bootstrap/scss/bootstrap.scss';
}

.gu-mirror {
    text-align: center !important;
    background-color: #007bff;
    border-radius: 3px;
}

.builder-component.gu-mirror {
    background-color: #fff !important;
    color: #000;
    border: #ccc solid 1px;
}

// Responsive settings
.formio-builder-wrapper {
    display: flex;
    flex-direction: row !important;
}

#bootstrap-formio {
    grid-area: builder;
    display: block;
    width: 70%;
}

.indicators-formio {
    grid-area: indicators;
    display: block;
    width: 30%;
}

@media screen and (max-width: 1300px) { // Smartphones

    // Responsive settings
    .formio-builder-wrapper {
        display: block;
    }

    #bootstrap-formio {
        width: 100% !important;
    }
    
    .indicators-formio {
        width: 100% !important;
    }
}

.input-variable-info {
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
}

// Indicator relationships container
.relationships-container {
    max-height: 500px !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #A1A4F3;
    }
}