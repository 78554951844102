.app-file-picker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .app-file-picker-input {
    position: relative;

    label {
      position: absolute;
      opacity: 0;
    }
  }
}
