.img-profile {
    border-radius: 50%;
    width: 150px !important;
    height: 150px !important;
}

.rounded {
    border-radius: 50%;
}

.center {
    display: flex;
    justify-content: center;
    margin-bottom: -6rem;
}

.title-user {
    margin-top: 5.5rem;
    display: block;
    width: 100%;
    text-align: center;
}