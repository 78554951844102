.survey-designer-wrapper{
    display: flex;
    flex-direction: row !important;
    width: 100%;
}

.indicator-survey {
    grid-area: indicators;
    display: block;
    width: 40% !important;
}

.question-survey {
    grid-area: quesdiv;
    display: block;
    width: 60% !important;
}

@media screen and (max-width: 1000px){
    .survey-designer-wrapper{
        display: block;
        margin-left: 4%;
    }

    .indicator-survey {
        width: 100% !important;
    }
    
    .question-survey {
        grid-area: quesdiv;
        display: block;
        margin-top: 5%;
        width: 100% !important;
    }
}