.app-subsystem {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.app-subsystem-graph {
    width: 100%;
}

.app-subsystem-components {
    width: 100%;
}

@media screen and (min-width: 992px) {
    .app-subsystem {
        flex-direction: row;
    }

    .app-subsystem-graph {
        flex: 1 1 0;
    }

    .app-subsystem-components {
        flex: 1 1 0;
        max-width: 250px;
    }
}