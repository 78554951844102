


//CUSTOM BG

.bg-dark {
    background-color: #3E4754 !important;
}

.bg-dark-low {
    background-color: #545B67;
}

.bg-light {
    background-color: #F2F4F6 !important;
}

.bg-dark-intense {
    background-color: #293241 !important;
}

.bg-light-intense {
    background-color: #ffffff !important;
}

.bg-silver-degraded {
    background-image: radial-gradient(circle at 14.46% 50%, #d3e5e3 0, #cad3d2 25%, #bebebe 50%, #b2a9ac 75%, #a7999e 100%);
}

.bg-gold-degraded {
    background-image: radial-gradient(circle at 14.46% 50%, #ffff5b 0, #ffd700 50%, #ddad00 100%);
}

.bg-diamond-degraded {
    background-image: radial-gradient(circle at 14.46% 50%, #f1ffff 0, #d7ffff 25%, #b9f2ff 50%, #9bdff1 75%, #80cee4 100%);
}

.bg-greenyellow-degraded {
    background-image: linear-gradient(90deg, #ffff00 0, #ffff00 12.5%, #efff0f 25%, #ceff22 37.5%, #adff2f 50%, #8cf239 62.5%, #6ae643 75%, #45db4b 87.5%, #00d154 100%);
}

.bg-blue-degraded {
    background-image: linear-gradient(90deg, #bb00ff 0, #7a00ff 25%, #0000ff 50%, #000bde 75%, #0011be 100%);
}

.bg-aqua-degraded {
    background-image: linear-gradient(90deg, #49ffff 0, #2dffff 16.67%, #10ffff 33.33%, #00ffff 50%, #0ee8f0 66.67%, #1dd3e2 83.33%, #28c1d4 100%);
}

/* CUSTOM COLOR */

.color-danger {
    color: #F48FB1;
}

.color-warning {
    color: #e7c27d;
}

.color-success {
    color: #C5E1A5;
}

.color-primary {
    color: #777BF1;
}

.color-black {
    color: black;
}

.color-white {
    color: white;
}

.required-input {
    color: #F48FB1;
}

/* CUSTOM STATUS */

.status-danger, 
.status-dark, 
.status-info, 
.status-primary, 
.status-success, 
.status-warning, 
.status-violet, 
.status-indigo, 
.status-purple {
    border-radius: 5px;
    font-size: .8rem;
    padding: 2px 3px;
}

.status-danger {
    color: #e74a3b;
    border: 1px solid #e74a3b;
}

.status-success {
    color: #1cc88a;
    border: 1px solid #1cc88a;
}

.status-info {
    color: #81D4FA;
    border: 1px solid #81D4FA;
}

.status-warning {
    color: #f6c23e;
    border: 1px solid #f6c23e;
}

.status-dark {
    color: #5a5c69;
    border: 1px solid #5a5c69;
}

.status-primary {
    color: #A1A4F3;
    border: 1px solid #A1A4F3;
}

.status-violet {
    color: violet;
    border: 1px solid violet;
}

.status-indigo {
    color: #6610f2;
    border: 1px solid #6610f2;
}

.status-purple {
    color: #6f42c1;
    border: 1px solid #6f42c1;
}

/* CUSTOM TEXT */

.text-line-through {
    text-decoration: line-through;
}

/* CUSTOM BORDERS */

.border {
    border: 1px solid gray;
}

/* Aditionlas Custom **************************************************************************************************************** */

label.required::after {
    content: '*';
    color: #F48FB1;
    padding-left: 1px;
    font-size: 1rem;
    
}

.p-field > label {
    font-size: .9rem;
}

.cursor-pointer {
    cursor: pointer;
}

.hashtag {
    color: #1DA1F2 !important;
}

.sidebar-pricing {
    width: 45% !important;
}

@media screen and (max-width: 1000px) { // Smartphones
    .sidebar-pricing {
        width: 70% !important;
    }
}

@media screen and (max-width: 500px) { // Smartphones
    .sidebar-pricing {
        width: 85% !important;
    }
}

/* Custom Glogal Changes ***************************************************************************************************************/

/* Delete buttons of input type number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

/* Custom color in grobal placeholder */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray;
    opacity: 10; /* Firefox */
}

.py-divider-container {
    .p-divider-content {
        background-color: #3E4754 !important;
    }
}

// Custom global scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #49678f 0, #293241 50%, #000000 100%);
}

/* HOVERS GLOBAL */

.hover-shadow:hover {
    transition: ease .3s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;    
}

.hover-shadow-zoom {

    transition: ease .3s;

    &:hover {
        transform: scale(1.03);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;    
    }
}

.hover-shadow-zoom-12 {

    transition: ease .3s;

    &:hover {
        transform: scale(1.2);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;    
    }
}

.bjs-powered-by svg {
    color: #eee;
}

/* Custom animation.style props */

/* This changes all the animations globally */
:root {
    --animate-duration: 700ms;
    --animate-delay: 0.9s;
}